import Link from "next/link";
import Logo from "components/atoms/Logo";
import { ISection } from "components/Contentful/Section/types";
import { Entry } from "contentful";
import { BLOCKS, Document } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { IAction } from "components/Contentful/Action/types";
import { CONTENTFUL_FOOTER_SECTION_ID } from "../../../env";
import { FooterWrap, StyledButton, Icon, Attribution } from "./styles";
import { useComponent } from "lib/contentful/use-component";

function LinkRows({ items }: { items: Entry<IAction>[] }) {
  if (!items) {
    return <></>;
  }
  return (
    <>
      {items.map((action) => {
        return action.fields.icon ? (
          <a
            key={action.sys.id}
            href={action.fields.url}
            style={{ margin: "0px 0" }}
          >
            <Icon src={action.fields.icon.fields.file.url} alt="Linkedin" />
          </a>
        ) : (
          <div></div>
        );
      })}
    </>
  );
}

const richTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node: Document) => {
      if (node?.data?.target?.sys === "action") {
        const { url, text } = node.data.target.fields;
        return (
          <StyledButton
            asLink
            href={url}
            icon="/images/icons/arrow-forward-white.svg"
          >
            {text}
          </StyledButton>
        );
      }
    },
  },
};

function Footer(): JSX.Element {
  const footerSection = useComponent<ISection>(
    CONTENTFUL_FOOTER_SECTION_ID,
    true
  );
  return (
    <FooterWrap>
      <nav>
        <div className="column">
          <Logo width={150} />
        </div>
        {footerSection?.fields?.items?.map((item, idx) => {
          const section = item as Entry<ISection>;
          const { text, items } = section.fields;
          return (
            <div className="column" key={`footer-column-${idx}`}>
              {documentToReactComponents(text, richTextOptions)}
              <LinkRows items={items as Entry<IAction>[]} />
            </div>
          );
        })}
      </nav>
      <Attribution>
        {footerSection?.fields?.links?.map((entry, index) => {
          const link = entry as Entry<{
            text: string;
            url: string;
          }>;
          const { text, url } = link.fields;
          return (
            <Link href={url} passHref key={`${index}-${url}`}>
              <a>{text}</a>
            </Link>
          );
        })}
      </Attribution>
    </FooterWrap>
  );
}

export default Footer;
