import styled from "@emotion/styled";
import Link from "next/link";
import { useTheme } from "theme";

const A = styled.a<{ opacity?: number }>`
  opacity: ${(props) => props.opacity};
  display: flex;
  img {
    height: 30px;
  }
`;

export interface LogoProps {
  /** The width of the logo **/
  width: number;
  /** Optional minimum width of the logo **/
  mWidth?: number;
  /** Optional opacity for the logo (defaults to 1)**/
  opacity?: number;
}

function Logo({ width, mWidth, opacity = 1 }: LogoProps): JSX.Element {
  const theme = useTheme();
  const { productName } = theme.meta;

  const logoUrl = `/images/${theme.key}/logo.svg`;

  return (
    <Link href="/" passHref prefetch={!process.env.STORYBOOK}>
      <A
        style={{ display: "flex", position: "relative", alignItems: "center" }}
        opacity={opacity}
      >
        <img
          src={logoUrl}
          alt={productName}
          style={{ width, maxWidth: mWidth }}
        />
      </A>
    </Link>
  );
}

export default Logo;
