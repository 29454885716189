import styled from "@emotion/styled";
import Button from "components/atoms/Button";

export const FooterWrap = styled.footer`
  grid-area: ft;
  padding: 50px 100px;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.fontLight};
  line-height: 1.43;
  font-size: 14px;
  letter-spacing: 0.3px;
  font-weight: 400;
  @media (max-width: 768px) {
    flex-flow: column wrap;
    align-items: center;
    font-size: 20px;
    padding: 50px 40px;
  }
  h3 {
    margin-top: 0;
    font-size: 24px;
  }
  a {
    margin: 2px 0;
    color: ${({ theme }) => theme.colors.fontLight};
    &:hover {
      text-decoration: underline;
    }
  }

  nav {
    display: flex;
    flex-flow: row wrap;
    padding: 1em 0;
    @media (max-width: 768px) {
      flex-flow: column;
    }
  }
  ul {
    list-style-type: none;
  }
  .column {
    display: flex;
    flex-flow: column;
    max-width: 150px;
    width: 100%;
    padding-bottom: 20px;
    @media (min-width: 769px) {
      max-width: 250px;
    }
  }

  blockquote {
    padding-bottom: 10px;
    font-style: italic;
    white-space: pre-line;
  }

  p {
    margin: 0;
  }

  h4 {
    color: ${({ theme }) => theme.colors.fontLight};
    margin: 0 0 10px;
  }
`;
export const StyledButton = styled(Button)`
  min-width: 170px;
  width: 170px;
  font-size: 16px;
  @media (max-width: 768px) {
    height: 50px;
    border-radius: 30px;
    font-weight: 600;
  }
`;
export const Icon = styled.img`
  margin: -10px;
  width: 50px;
  height: 50px;
  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

export const Attribution = styled.div`
  padding: 10px 0 60px;
  font-size: 14px;
  font-weight: 300;

  a {
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
    padding: 0 4px;
    border-right: 1px solid ${({ theme }) => theme.colors.white};
    :last-child {
      border-right: none;
    }
  }
  @media (max-width: 768px) {
    text-align: center;
  }
`;
