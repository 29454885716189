import { memo, Fragment, useState, useEffect } from "react";
import Logo from "components/atoms/Logo";
import Link from "next/link";
import dynamic from "next/dynamic";
import { isMobile } from "utils/mobile-check";
import { contentful } from "../../../lib/contentful";
import { Sys } from "contentful";
import { useFeature } from "lib/features";
import { LogoWrapper, Wrapper, PreviewBanner } from "./styles";
import { CONTENTFUL_SIDE_MENU_ID } from "../../../env";

const HeaderMenu = dynamic(() => import("components/Header/HeaderMenu"), {
  ssr: false,
});

const TickerContainer = dynamic(() => import("containers/TickerContainer"), {
  ssr: false,
});

const OrganisationPicker = dynamic(
  () => import("components/Header/OrganisationPicker"),
  {
    ssr: false,
  }
);

const LogoComponent = () => (
  <LogoWrapper>
    <Logo width={95} />
  </LogoWrapper>
);

type Props = {
  preview?: boolean;
  logoOnly?: boolean;
};

type MenuItems = {
  name: string;
  slug: string;
};

type EntryType = {
  fields: {
    sideMenu: string;
    menuItems: {
      fields: MenuItems;
      sys: Sys;
    }[];
  };
  sys: Sys;
};

function Header({ logoOnly, preview }: Props) {
  const [menuItems, setMenuItems] = useState<MenuItems[] | []>([]);
  const mobile: boolean = global && global.window && isMobile();

  const { hasTicker, hasOrganisationPicker } = useFeature();

  useEffect(() => {
    if (CONTENTFUL_SIDE_MENU_ID) {
      getMenuItems();
    }
    async function getMenuItems() {
      const res: EntryType = await contentful(preview).getEntry(
        CONTENTFUL_SIDE_MENU_ID
      );

      const items: MenuItems[] =
        res && res.fields && res.fields.menuItems
          ? res.fields.menuItems.map(({ fields }) => ({
              name: fields.name,
              slug: fields.slug,
            }))
          : [];

      setMenuItems(items);
    }
  }, [preview]);
  return (
    <>
      <Wrapper>
        {preview && (
          <PreviewBanner>
            <div>Preview mode:</div>
            <div>
              <Link href="/api/preview-disable">Exit Preview Mode</Link>
            </div>
          </PreviewBanner>
        )}
        <LogoComponent />

        {!logoOnly && (
          <Fragment>
            {!mobile && hasTicker && <TickerContainer noNav={false} />}
            {!mobile && hasOrganisationPicker && <OrganisationPicker />}
            <HeaderMenu menuItems={menuItems} />
          </Fragment>
        )}
      </Wrapper>
    </>
  );
}

export default memo(Header);
