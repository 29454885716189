import styled from "@emotion/styled";

export const Wrapper = styled.header`
  background-color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  grid-area: hd;
  justify-content: space-between;
  padding: 0;
  position: sticky;
  top: 0;
  height: 57px;
  z-index: 1003;
  padding: 10px 0;
  flex-flow: column nowrap;

  @media (min-width: 769px) {
    align-items: center;
    height: 75px;
    flex-flow: row nowrap;
  }
`;

export const LogoWrapper = styled.section`
  width: 146px;
  margin-left: 30px;
  position: relative;
  opacity: 1;

  @media ${({ theme }) => theme.breakpoints.mobile} {
    margin-left: 20px;
    margin-top: 5px;
  }
`;

export const PreviewBanner = styled.div`
  display: flex;
  width: 300px;
  justify-content: space-around;
  padding: 1em;
  position: fixed;
  right: 0;
  top: 74px;
  background: white;
  border: 1px solid black;
`;
